import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { RichText } from "../../internal-components/richText/RichText.js";

interface Props {
  image?: Image;
  text?: string;
  button?: Link;
  position?: "left" | "right";
}

const ImageAndText: React.FC<Props> = ({
  image,
  text,
  button,
  position = "left",
}) => (
  <div className="image-and-text mw hs vs-xl">
    <div className={`container ${position}`}>
      {image && (
        <div className="image-container">
          <img
            src={transformImageUrl(image, { width: 800, height: 800 })}
            alt={image.altText}
          />
        </div>
      )}
      <div className="text-container">
        {text && <RichText html={text} />}
        {button && (
          <div className="button-container">
            <a href={button.url} className="button">
              {button.title}
            </a>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default ImageAndText;
